import React from "react";
import WritingTemplate from "../../components/writing/template";
import Card from "../../components/writing/template/card";

import juniordesigner from "../../images/writing/juniordesignerheader.png";
import ux from "../../images/writing/ux.jpg";
import tools from "../../images/writing/tools.png";

export default function () {
  return (
    <WritingTemplate
      title="For every Junior Designer"
      subtitle="A resource filled library for Designers"
      imageLink={juniordesigner}
    >
      <Card>
        <p className="different">
          Hello👋, I decided to put together a plethora of resources that have
          helped me in my Product Design journey. Hope you find it helpful.
          Let’s get started!
        </p>
        <div className="ux">
          <p className="bold">What is UI/UX</p>
          <img src={ux} alt="What is UX" />
          <p className="caption different">Illustration from Webit Factory</p>
        </div>
        <p>
          User interface (UI) design according to Emil Lampretch, is a purely
          digital practice. It considers all the visual, interactive elements of
          a product interface – including buttons, icons, spacing, typography,
          color schemes, and responsive design. On the other hand, User
          experience (UX) design is the process of developing and improving the
          quality of interaction between a user and all facets of a company.
        </p>
        <div className="highlight">
          <p className="different">To simplify:</p>
          <div className="highlight__content">
            A UI designer seeks to put out a visually appealing product; to make
            you open a screen and say ‘mad oo’ while a UX designer is concerned
            about how you feel using the product. For instance, the feeling you
            get from clicking a button multiple times and it doesn’t bring the
            desired result can be termed bad UX.
          </div>
        </div>
      </Card>
      <Card>
        <p className="different">There are many roles within UI/UX, we have:</p>
        <ul>
          <li>UX Consultant</li>
          <li>UX Designers</li>
          <li>UX Researcher</li>
          <li>UX Analyst</li>
          <li>UX Strategist</li>
          <li>UI Designer</li>
          <li>Information Architect</li>
          <li>Interaction Designer</li>
          <li>Visual Designer and so on</li>
        </ul>
      </Card>
      <Card>
        <div className="new">
          <p className="bold">Tools and Resources</p>
          <img src={tools} alt="List of tools" />
          <p className="caption">Illustration from Webit Factory</p>
          <p className="different text-center">
            There are quite a number of tools you can use to get started in
            UI/UX. However, you do not need to master them all. The required
            tool will be listed in the job description. There are:
          </p>
          <ul>
            <li>
              <span>Figma</span> – Free with additional paid plans
            </li>
            <li>
              <span>Adobe XD</span> – Free with additional paid plans
            </li>
            <li>
              <span>Sketch</span> – Paid
            </li>
            <li>
              <span>Invision</span> – Free
            </li>
            <li>
              <span>Framer</span> – Free
            </li>
            <li>
              <span>Proto.io</span> – $24 a month
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Courses</p>
          <p>
            These platforms provide courses that will introduce you to design
            process and supply all the information required as well as a
            certificate of completion which you can attach to your portfolio
          </p>
          <ul>
            <li>
              <span>Udemy</span> – Paid
            </li>
            <li>
              <span>Interaction Design Foundation</span> – Paid
            </li>
            <li>
              <span>Future Learn</span> – Free
            </li>
            <li>
              <span>Coursera</span> – Paid
            </li>
          </ul>
        </div>
      </Card>
      <Card>
        <div className="new">
          <p className="bold">YouTube Channels</p>
          <p>
            Here are some channels that are the best when it comes to delivering
            rock-solid information on UI, UX, Freelancing, design process and
            selected tools
          </p>
          <ul>
            <li>
              <span>Flux</span> – Ran Segall, a designer, shares his journey,
              struggles and lessons he learned as he builds his career as a
              designer and a business owner.
            </li>
            <li>
              <span>Design Code</span> – Design reviews and techniques.
            </li>
            <li>
              <span>Design Course</span> –Tutorials, experiments, crash courses
              and design review
            </li>
            <li>
              <span>Design Pilot</span> – Design Process, tutorials, tips and
              resources.
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Ux Websites and Blogs</p>
          <ul>
            <li>
              <span>Smashing Magazine</span> – A comprehensive website provides
              high-quality articles with the UX employee on Design, Coding,
              Mobile, and WordPress,etc.
            </li>
            <li>
              <span>UX Booth</span> – A professional UX website. The difference
              between it and Smashing Magazine is that it focuses more on the
              aspect of user experience design.
            </li>
            <li>
              <span>Invision Blog</span> – It’s a blog with very simple and
              clean interface, no more distraction from advertisements or
              others. Articles are all surrounding the topics of design tools,
              UI/UX design, web design, and mobile app design. A good design
              topic resource to follow.
            </li>
            <li>
              <span>Invision Blog</span> – It’s a blog with very simple and
              clean interface, no more distraction from advertisements or
              others. Articles are all surrounding the topics of design tools,
              UI/UX design, web design, and mobile app design. A good design
              topic resource to follow.
            </li>
            <li>
              <span>Toptal Designers Blog</span> – A hub for in-depth design
              tutorials and coverage of new design trends, created by
              professional freelance designers in the Toptal network.
            </li>
            <li>
              <span>Design Modo</span> – All-in-one solution to create website
              and newsletter design to impress and engage your customers.
            </li>
            <li>
              <span>The UX Collective</span> – The UX Collective is an
              independent design publication built to elevate unheard design
              voices all over the world. Curated stories on user experience,
              visual and product
            </li>
            <li>
              <span>Design Shack</span> – Design Shack is a daily source of
              helpful resources, articles, inspiration, tips, and tutorials for
              the design community.
            </li>
            <li>
              <span>Creative Bloq</span> – Daily inspiration for creative
              people. Fresh thinking, expert tips and tutorials to supercharge
              your creative muscles.
            </li>
          </ul>
        </div>
      </Card>
      <Card>
        <div className="new">
          <p className="bold">Fonts</p>
          <ul>
            <li>
              <span>Font Squirrel</span> – Free, hand-picked, high-quality,
              commercial-use fonts
            </li>
            <li>
              <span>Google Fonts</span> – Google Fonts makes product and web
              pages run faster by safely caching fonts without compromising
              users’ privacy or security.
            </li>
            <li>
              <span>Dafont</span> – Free fonts to download.
            </li>
            <li>
              <span>Typewolf</span> – The best resource available for everything
              related to typography on the web.
            </li>
            <li>
              <span>Identify Font</span> – Identify a font by answering
              questions about key features
            </li>
            <li>
              <span>WhatTheFont</span> – Seen a font in use and want to know
              what it is? This is your guy.
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Icons</p>
          <ul>
            <li>
              <span>The Noun Project</span> – Over a million curated icons,
              created by a global community.
            </li>
            <li>
              <span>Flat Icon</span> – G The largest database of free icons
              available in PNG, SVG, EPS, PSD and BASE 64 formats.
            </li>
            <li>
              <span>Icons8</span> – Free Icons by First-Class Designers.
            </li>
            <li>
              <span>Iconscout</span>
            </li>
            <li>
              <span>Feather icons</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Inspirations</p>
          <ul>
            <li>
              <span>Muzli</span> – The best design inspiration expertly curated
              for you.
            </li>
            <li>
              <span>Design Munk</span>
            </li>
            <li>
              <span>Lapa.ninja</span>
            </li>
            <li>
              <span>Collectui.com</span>
            </li>
            <li>
              <span>UI Parade</span> – UI Inspirations.
            </li>
            <li>
              <span>UI Patterns</span> – User Interface Design patterns are
              recurring solutions that solve common design problems.
            </li>
            <li>
              <span>Behance</span>
            </li>
            <li>
              <span>Dribbble</span>
            </li>
            <li>
              <span>Uijar</span>
            </li>
            <li>
              <span>Minimal Gallery</span>
            </li>
          </ul>
        </div>
      </Card>
      <Card>
        <div className="new">
          <p className="bold">Stock Photos</p>
          <ul>
            <li>
              <span>Pexels</span> – Best free stock photos in one place.
            </li>
            <li>
              <span>Pixabay</span> – Pixabay is a vibrant community of
              creatives, sharing copyright free images and videos.
            </li>
            <li>
              <span>StockSnap</span> – Hundreds of high-resolution images added
              weekly.
            </li>
            <li>
              <span>Unsplash</span> – Beautiful, free photos. Gifted by the
              world’s most generous community of photographers.
            </li>
            <li>
              <span>Bantu Photos</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Vectors and Graphics</p>
          <ul>
            <li>
              <span>Free Vector</span> – A world of free vector art at your
              fingertips!
            </li>
            <li>
              <span>Vecteezy</span> – Discover & download free vector Art!
            </li>
            <li>
              <span>Freepik</span> – A world of free vector art at your
              fingertips
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Colors</p>
          <ul>
            <li>
              <span>Assessible Colors</span>
            </li>
            <li>
              <span>
                Graphic Style Manual for Understanding How Color Affects Design
              </span>{" "}
              – Color is an integral part of any design solution. Design
              Elements, Color Fundamentals is an essential resource for
              designers who want to create a memorable design and successfully
              communicate with their audience.
            </li>
            <li>
              <span>Coolors</span> – Generate or browse beautiful color
              combinations for your designs.
            </li>
            <li>
              <span>Color Combination</span>
            </li>
            <li>
              <span>Color Inspo</span>
            </li>
            <li>
              <span>Color hunt</span>
            </li>
            <li>
              <span>Material Palette</span>
            </li>
          </ul>
        </div>
      </Card>
      <Card>
        <div className="new">
          <p className="bold">Illustration</p>
          <ul>
            <li>
              <span>Undraw</span>
            </li>
            <li>
              <span>Undrawkit.</span>
            </li>
            <li>
              <span>Illustration.co</span>
            </li>
            <li>
              <span>Manypixels.co</span>
            </li>
            <li>
              <span>Humaaans.com</span>
            </li>
            <li>
              <span>Black illustrations</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Mock-ups</p>
          <ul>
            <li>
              <span>Mockupworld</span>
            </li>
            <li>
              <span>Is.graphics</span>
            </li>
            <li>
              <span>Graphicburger</span>
            </li>
            <li>
              <span>Free design resources</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Wireframing</p>
          <ul>
            <li>
              <span>Flowmapp</span>
            </li>
            <li>
              <span>Mockflow</span>
            </li>
            <li>
              <span>Mockplus</span>
            </li>
            <li>
              <span>Figma</span>
            </li>
            <li>
              <span>Wireframe.cc</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Podcasts</p>
          <ul>
            <li>
              <span>Design matters Podcast by Debbie Millman</span>
            </li>
            <li>
              <span>UI Breakfast Podcast</span>
            </li>
            <li>
              <span>The Futur Podcast</span>
            </li>
          </ul>
        </div>
        <div className="new">
          <p className="bold">Additional Resources</p>
          <ul>
            <li>
              <span>Daily UI</span> – A 100 day of practicing UI that will help
              you to boost your UI skills
            </li>
            <li>
              <span>Whimsical</span> –Whimsical is a unified suite of
              collaboration tools. Great for product specs, wikis,
              brainstorming, ideation, user flows, architecture diagrams and
              more.
            </li>
          </ul>
        </div>
      </Card>
    </WritingTemplate>
  );
}
